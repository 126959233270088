.containerComp {
  background: var(--homepageBgCompetition);
  background-size: cover; }

.compbuttons {
  display: flex;
  flex-direction: row;
  border: 1px solid red; }

.compbuttons > div {
  flex: 50%;
  border: 1px solid green; }

.leaderboard {
  background: var(--leaderboard-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: left; }

.tos {
  background: var(--help-icon);
  background-repeat: no-repeat;
  background-size: cover;
  float: right; }

.compbtn button {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: none;
  outline: none; }

.moreGame {
  width: 260px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  background-color: transparent; }

.compHomeGames {
  width: 56% !important;
  margin-top: 2px !important;
  flex-wrap: wrap; }

.btnspage {
  min-height: 85px;
  width: 56%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.btnhomeramdan {
  background: url("../../assets/images/HomepagecomGamezroid/homebtbg.png") no-repeat center center;
  background-size: contain;
  color: var(--textColor);
  padding: 10px;
  height: 69px;
  line-height: 9px;
  width: 110px;
  border: 1px solid transparent;
  border-radius: 15px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  font-family: SourceSansPro-Bold;
  transition: all ease 0.4s; }

.btnhomeramdan p {
  margin-top: 11px; }

.imgGame img {
  width: 100px;
  height: 100px; }

.gameCompName {
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  color: #fff;
  padding: 2px 7px 9px 7px;
  text-transform: uppercase; }

.coins {
  width: 100px;
  height: 75px;
  position: absolute;
  top: 22%;
  left: 24%; }

body[dir='rtl'] .btnhomeramdan {
  font-size: 20px; }

@media (max-width: 1024px) {
  .compHomeGames {
    width: 61% !important; }
  .coins {
    left: -26px; } }

@media (max-width: 950px) {
  .compHomeGames {
    width: 30% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; } }

@media (max-width: 768px) {
  .btnspage {
    width: 62%; }
  .compbtn {
    width: 65% !important; }
  .spaceTop {
    height: 3vh !important; }
  .compHomeGames {
    width: 65% !important; }
  .containerComp {
    min-height: 113vh !important;
    background-size: cover; }
  .imgGame img {
    width: 90px;
    height: 90px; }
  .section-home {
    width: 72% !important; } }

@media (max-width: 540px) {
  .btnspage {
    width: 75%; }
  .containerComp {
    height: 719px !important;
    background-size: cover; }
  .compHomeGames {
    width: 77% !important; }
  .spaceTop {
    height: 7vh !important; }
  .section-home {
    width: 86% !important; }
  .compbtn {
    width: 80% !important; } }

@media (max-width: 418px) {
  .btnspage {
    width: 100%; }
  .containerComp {
    min-height: 112vh;
    background-size: cover; }
  .compbtn {
    width: 100% !important; }
  .spaceTop {
    height: 6vh !important; } }

@media (max-width: 418px) {
  .containerComp {
    height: 873px !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; } }

@media (max-width: 411px) {
  .spaceTop {
    height: 2vh !important; }
  .containerComp {
    background-size: cover; } }

@media (max-width: 400px) {
  .containerComp {
    height: 838px !important;
    background-size: cover; } }

@media (max-width: 376px) {
  .containerComp {
    height: 814px !important;
    min-height: 108vh !important;
    background-size: cover; }
  .coins {
    top: 102px; } }

@media (max-width: 360px) {
  .spaceTop {
    height: 5vh !important; }
  .coins {
    top: 120px; } }

@media (max-width: 320px) {
  .links-SectionCompetition {
    width: 98% !important; }
  .containerComp {
    height: 567px !important;
    min-height: 130vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .imgGame img {
    width: 75px;
    height: 75px; }
  .coins {
    top: 100px;
    left: -3px; }
  .btnhomeramdan {
    width: 100px; }
  .headerCompetion {
    margin-top: 0 !important; } }

@media (max-width: 280px) {
  .containerComp {
    height: 651px !important;
    min-height: 114vh !important;
    background-size: cover; }
  .compHomeGames {
    width: 100% !important; }
  .links-SectionCompetition {
    width: 100% !important; }
  .logoComp {
    width: 130px !important; } }

@media (max-device-width: 1024px) and (orientation: landscape) {
  .containerComp {
    min-height: 109vh !important; } }

@media (max-device-width: 993px) and (orientation: landscape) {
  .containerComp {
    min-height: 170vh !important; } }

@media (max-device-width: 882px) and (orientation: landscape) {
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 823px) and (orientation: landscape) {
  .containerComp {
    min-height: 172vh !important; } }

@media (max-device-width: 768px) and (orientation: landscape) {
  .containerComp {
    min-height: 184vh !important; } }

@media (max-device-width: 640px) and (orientation: landscape) {
  .spaceTop {
    height: 4vh !important; }
  .containerComp {
    min-height: 162vh !important; } }

@media (max-device-width: 653px) and (orientation: landscape) {
  .spaceTop {
    height: 0 !important; }
  /*.containerComp {
        min-height: 226vh !important;
    }*/ }

.ice {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  overflow: hidden;
  pointer-events: none; }

.g-snows {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0; }

.g-snows > li {
  opacity: 0;
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: url("../../assets/images/competition/partical1.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: snow-drop;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; }
  .g-snows > li:nth-child(1) {
    left: 40%;
    width: 23px;
    height: 23px;
    animation-duration: 12692ms;
    animation-delay: 1670ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(2) {
    left: 75%;
    width: 0px;
    height: 0px;
    animation-duration: 7074ms;
    animation-delay: 3546ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(3) {
    left: 99%;
    width: 11px;
    height: 11px;
    animation-duration: 10018ms;
    animation-delay: 865ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(4) {
    left: 9%;
    width: 9px;
    height: 9px;
    animation-duration: 6062ms;
    animation-delay: 4823ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(5) {
    left: 9%;
    width: 4px;
    height: 4px;
    animation-duration: 9823ms;
    animation-delay: 2657ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(6) {
    left: 50%;
    width: 2px;
    height: 2px;
    animation-duration: 10060ms;
    animation-delay: 220ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(7) {
    left: 17%;
    width: 15px;
    height: 15px;
    animation-duration: 12820ms;
    animation-delay: 2431ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(8) {
    left: 0%;
    width: 15px;
    height: 15px;
    animation-duration: 9282ms;
    animation-delay: 1200ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(9) {
    left: 69%;
    width: 14px;
    height: 14px;
    animation-duration: 7731ms;
    animation-delay: 182ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(10) {
    left: 27%;
    width: 7px;
    height: 7px;
    animation-duration: 8943ms;
    animation-delay: 4371ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(11) {
    left: 10%;
    width: 1px;
    height: 1px;
    animation-duration: 9468ms;
    animation-delay: 3385ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(12) {
    left: 9%;
    width: 2px;
    height: 2px;
    animation-duration: 12039ms;
    animation-delay: 3102ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(13) {
    left: 35%;
    width: 2px;
    height: 2px;
    animation-duration: 10539ms;
    animation-delay: 1064ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(14) {
    left: 127%;
    width: 20px;
    height: 20px;
    animation-duration: 6027ms;
    animation-delay: 4494ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(15) {
    left: 67%;
    width: 6px;
    height: 6px;
    animation-duration: 9897ms;
    animation-delay: 3170ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(16) {
    left: 11%;
    width: 10px;
    height: 10px;
    animation-duration: 10456ms;
    animation-delay: 2027ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(17) {
    left: 39%;
    width: 5px;
    height: 5px;
    animation-duration: 11776ms;
    animation-delay: 3501ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(18) {
    left: 51%;
    width: 6px;
    height: 6px;
    animation-duration: 5945ms;
    animation-delay: 1079ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(19) {
    left: 66%;
    width: 16px;
    height: 16px;
    animation-duration: 5621ms;
    animation-delay: 2352ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(20) {
    left: 54%;
    width: 9px;
    height: 9px;
    animation-duration: 7021ms;
    animation-delay: 2538ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(21) {
    left: 44%;
    width: 19px;
    height: 19px;
    animation-duration: 9193ms;
    animation-delay: 1968ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(22) {
    left: 26%;
    width: 4px;
    height: 4px;
    animation-duration: 5139ms;
    animation-delay: 2762ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(23) {
    left: 6%;
    width: 16px;
    height: 16px;
    animation-duration: 6561ms;
    animation-delay: 3120ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(24) {
    left: 63%;
    width: 0px;
    height: 0px;
    animation-duration: 8866ms;
    animation-delay: 4274ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(25) {
    left: 103%;
    width: 0px;
    height: 0px;
    animation-duration: 5831ms;
    animation-delay: 2217ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(26) {
    left: 31%;
    width: 14px;
    height: 14px;
    animation-duration: 7390ms;
    animation-delay: 4168ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(27) {
    left: 117%;
    width: 1px;
    height: 1px;
    animation-duration: 5423ms;
    animation-delay: 1581ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(28) {
    left: 20%;
    width: 14px;
    height: 14px;
    animation-duration: 9267ms;
    animation-delay: 4602ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(29) {
    left: 97%;
    width: 25px;
    height: 25px;
    animation-duration: 10995ms;
    animation-delay: 1334ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(30) {
    left: 71%;
    width: 7px;
    height: 7px;
    animation-duration: 9298ms;
    animation-delay: 4247ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(31) {
    left: 48%;
    width: 3px;
    height: 3px;
    animation-duration: 5528ms;
    animation-delay: 2361ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(32) {
    left: 119%;
    width: 5px;
    height: 5px;
    animation-duration: 5758ms;
    animation-delay: 1047ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(33) {
    left: 95%;
    width: 24px;
    height: 24px;
    animation-duration: 6782ms;
    animation-delay: 4113ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(34) {
    left: 69%;
    width: 1px;
    height: 1px;
    animation-duration: 11097ms;
    animation-delay: 947ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(35) {
    left: 93%;
    width: 3px;
    height: 3px;
    animation-duration: 8655ms;
    animation-delay: 1274ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(36) {
    left: 67%;
    width: 7px;
    height: 7px;
    animation-duration: 10334ms;
    animation-delay: 3085ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(37) {
    left: 21%;
    width: 22px;
    height: 22px;
    animation-duration: 11050ms;
    animation-delay: 1217ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(38) {
    left: 11%;
    width: 5px;
    height: 5px;
    animation-duration: 10865ms;
    animation-delay: 4113ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(39) {
    left: 13%;
    width: 17px;
    height: 17px;
    animation-duration: 8025ms;
    animation-delay: 1365ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(40) {
    left: 100%;
    width: 22px;
    height: 22px;
    animation-duration: 11652ms;
    animation-delay: 1412ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(41) {
    left: 26%;
    width: 14px;
    height: 14px;
    animation-duration: 8808ms;
    animation-delay: 4703ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(42) {
    left: 49%;
    width: 5px;
    height: 5px;
    animation-duration: 5778ms;
    animation-delay: 1604ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(43) {
    left: 25%;
    width: 18px;
    height: 18px;
    animation-duration: 11738ms;
    animation-delay: 4773ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(44) {
    left: 36%;
    width: 15px;
    height: 15px;
    animation-duration: 9248ms;
    animation-delay: 3212ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(45) {
    left: 41%;
    width: 22px;
    height: 22px;
    animation-duration: 9267ms;
    animation-delay: 2678ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(46) {
    left: 69%;
    width: 2px;
    height: 2px;
    animation-duration: 5806ms;
    animation-delay: 4803ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(47) {
    left: 66%;
    width: 15px;
    height: 15px;
    animation-duration: 11220ms;
    animation-delay: 4266ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(48) {
    left: 52%;
    width: 12px;
    height: 12px;
    animation-duration: 6401ms;
    animation-delay: 746ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(49) {
    left: 17%;
    width: 3px;
    height: 3px;
    animation-duration: 5335ms;
    animation-delay: 1133ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(50) {
    left: 115%;
    width: 4px;
    height: 4px;
    animation-duration: 7427ms;
    animation-delay: 3402ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(51) {
    left: 62%;
    width: 2px;
    height: 2px;
    animation-duration: 8444ms;
    animation-delay: 4150ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(52) {
    left: 32%;
    width: 2px;
    height: 2px;
    animation-duration: 6299ms;
    animation-delay: 4026ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(53) {
    left: 48%;
    width: 8px;
    height: 8px;
    animation-duration: 6860ms;
    animation-delay: 3190ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(54) {
    left: 88%;
    width: 2px;
    height: 2px;
    animation-duration: 8902ms;
    animation-delay: 1610ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(55) {
    left: 68%;
    width: 2px;
    height: 2px;
    animation-duration: 7599ms;
    animation-delay: 2565ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(56) {
    left: 14%;
    width: 8px;
    height: 8px;
    animation-duration: 9267ms;
    animation-delay: 229ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(57) {
    left: 33%;
    width: 15px;
    height: 15px;
    animation-duration: 6872ms;
    animation-delay: 551ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(58) {
    left: 82%;
    width: 17px;
    height: 17px;
    animation-duration: 13505ms;
    animation-delay: 3839ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(59) {
    left: 7%;
    width: 9px;
    height: 9px;
    animation-duration: 10254ms;
    animation-delay: 3822ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(60) {
    left: 146%;
    width: 5px;
    height: 5px;
    animation-duration: 10683ms;
    animation-delay: 4757ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(61) {
    left: 3%;
    width: 15px;
    height: 15px;
    animation-duration: 8831ms;
    animation-delay: 2635ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(62) {
    left: 80%;
    width: 11px;
    height: 11px;
    animation-duration: 7804ms;
    animation-delay: 1951ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(63) {
    left: 0%;
    width: 13px;
    height: 13px;
    animation-duration: 10732ms;
    animation-delay: 1999ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(64) {
    left: 15%;
    width: 13px;
    height: 13px;
    animation-duration: 7259ms;
    animation-delay: 3541ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(65) {
    left: 87%;
    width: 4px;
    height: 4px;
    animation-duration: 7729ms;
    animation-delay: 477ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(66) {
    left: 55%;
    width: 6px;
    height: 6px;
    animation-duration: 7207ms;
    animation-delay: 748ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(67) {
    left: 61%;
    width: 8px;
    height: 8px;
    animation-duration: 5904ms;
    animation-delay: 4511ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(68) {
    left: 25%;
    width: 5px;
    height: 5px;
    animation-duration: 7755ms;
    animation-delay: 763ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(69) {
    left: 109%;
    width: 13px;
    height: 13px;
    animation-duration: 5432ms;
    animation-delay: 2356ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(70) {
    left: 105%;
    width: 18px;
    height: 18px;
    animation-duration: 10497ms;
    animation-delay: 607ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(71) {
    left: 77%;
    width: 3px;
    height: 3px;
    animation-duration: 5497ms;
    animation-delay: 4654ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(72) {
    left: 98%;
    width: 1px;
    height: 1px;
    animation-duration: 5316ms;
    animation-delay: 1031ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(73) {
    left: 44%;
    width: 11px;
    height: 11px;
    animation-duration: 8770ms;
    animation-delay: 4255ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(74) {
    left: 54%;
    width: 27px;
    height: 27px;
    animation-duration: 11356ms;
    animation-delay: 4269ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(75) {
    left: 10%;
    width: 10px;
    height: 10px;
    animation-duration: 12044ms;
    animation-delay: 1304ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(76) {
    left: 84%;
    width: 2px;
    height: 2px;
    animation-duration: 12597ms;
    animation-delay: 1370ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(77) {
    left: 139%;
    width: 4px;
    height: 4px;
    animation-duration: 10788ms;
    animation-delay: 3825ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(78) {
    left: 22%;
    width: 5px;
    height: 5px;
    animation-duration: 7361ms;
    animation-delay: 1525ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(79) {
    left: 1%;
    width: 17px;
    height: 17px;
    animation-duration: 9538ms;
    animation-delay: 2129ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(80) {
    left: 15%;
    width: 23px;
    height: 23px;
    animation-duration: 11031ms;
    animation-delay: 3192ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(81) {
    left: 54%;
    width: 12px;
    height: 12px;
    animation-duration: 12618ms;
    animation-delay: 1034ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(82) {
    left: 75%;
    width: 5px;
    height: 5px;
    animation-duration: 5172ms;
    animation-delay: 4378ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(83) {
    left: 108%;
    width: 11px;
    height: 11px;
    animation-duration: 6066ms;
    animation-delay: 3538ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(84) {
    left: 83%;
    width: 6px;
    height: 6px;
    animation-duration: 5436ms;
    animation-delay: 1566ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(85) {
    left: 51%;
    width: 19px;
    height: 19px;
    animation-duration: 6637ms;
    animation-delay: 4354ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(86) {
    left: 4%;
    width: 7px;
    height: 7px;
    animation-duration: 7729ms;
    animation-delay: 2623ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(87) {
    left: 1%;
    width: 1px;
    height: 1px;
    animation-duration: 11399ms;
    animation-delay: 1886ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(88) {
    left: 12%;
    width: 23px;
    height: 23px;
    animation-duration: 10518ms;
    animation-delay: 4099ms;
    filter: blur(0px); }
  .g-snows > li:nth-child(89) {
    left: 135%;
    width: 4px;
    height: 4px;
    animation-duration: 8219ms;
    animation-delay: 51ms;
    filter: blur(4px); }
  .g-snows > li:nth-child(90) {
    left: 89%;
    width: 19px;
    height: 19px;
    animation-duration: 13157ms;
    animation-delay: 566ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(91) {
    left: 38%;
    width: 11px;
    height: 11px;
    animation-duration: 13040ms;
    animation-delay: 3186ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(92) {
    left: 32%;
    width: 9px;
    height: 9px;
    animation-duration: 8884ms;
    animation-delay: 3874ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(93) {
    left: 60%;
    width: 24px;
    height: 24px;
    animation-duration: 5930ms;
    animation-delay: 2937ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(94) {
    left: 53%;
    width: 22px;
    height: 22px;
    animation-duration: 5730ms;
    animation-delay: 1823ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(95) {
    left: 130%;
    width: 1px;
    height: 1px;
    animation-duration: 9228ms;
    animation-delay: 769ms;
    filter: blur(3px); }
  .g-snows > li:nth-child(96) {
    left: 53%;
    width: 6px;
    height: 6px;
    animation-duration: 11072ms;
    animation-delay: 3379ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(97) {
    left: 56%;
    width: 6px;
    height: 6px;
    animation-duration: 13102ms;
    animation-delay: 4857ms;
    filter: blur(1px); }
  .g-snows > li:nth-child(98) {
    left: 104%;
    width: 15px;
    height: 15px;
    animation-duration: 11494ms;
    animation-delay: 3389ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(99) {
    left: 77%;
    width: 14px;
    height: 14px;
    animation-duration: 8208ms;
    animation-delay: 1061ms;
    filter: blur(2px); }
  .g-snows > li:nth-child(100) {
    left: 57%;
    width: 2px;
    height: 2px;
    animation-duration: 9713ms;
    animation-delay: 4926ms;
    filter: blur(0px); }

@keyframes snow-drop {
  0% {
    transform: translate(0, 0);
    opacity: 0.5;
    margin-left: 0; }
  10% {
    margin-left: 15px; }
  20% {
    margin-left: 20px; }
  25% {
    transform: translate(0, 166.66667px);
    opacity: 0.75; }
  30% {
    margin-left: 15px; }
  40% {
    margin-left: 0; }
  50% {
    transform: translate(0, 333.33333px);
    opacity: 1;
    margin-left: -15px; }
  60% {
    margin-left: -20px; }
  70% {
    margin-left: -15px; }
  75% {
    transform: translate(0, 500px);
    opacity: 0.5; }
  80% {
    margin-left: 0; }
  100% {
    transform: translate(0, 666.66667px);
    opacity: 0; } }
